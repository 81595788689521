// eslint-disable-next-line import/no-unresolved
// import Pristine from "pristinejs";

import "./lib/jquery.validate"
import "./lib/jquery.maskedinput";
import magnificPopup from 'magnific-popup';

export default function modals() {

    $('input[type="tel"]').mask("+7 (999) 999-99-99");


    $('.open-popup').magnificPopup({
      type: 'inline',
      midClick: true,
      removalDelay: 300,
      closeBtnInside: true,
      // closeMarkup: `<div class="mfp-close-button">
      //                 <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      //                   <rect x="14.2134" y="0.212891" width="2" height="30" fill="black"/>
      //                   <rect x="30.2134" y="14.2129" width="2" height="30" transform="rotate(90 30.2134 14.2129)" fill="black"/>
      //                 </svg>
      //               </div>`,
      callbacks: {
        beforeOpen() {
          this.st.mainClass = 'mfp-zoom-out';
        },
        open() {
          $('html').css('margin-right', 0);
          $('html').css('overflow', 'visible');
        }
      }
    });

    $('.popup-gallery').magnificPopup({
      delegate: '.main-gallery__item',
      type: 'image',
      tLoading: 'Загрузка #%curr%...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        tPrev: 'Назад', // title for left button
        tNext: 'Вперед', // title for right button
        tCounter: '%curr% из %total%',
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
        titleSrc(item) {
          return `${item.el.attr('title')  }`;
        }
      }
    });

//  const formList = document.querySelectorAll("[data-form-validate]");

//   formList.forEach((form) => {
//     const pristine = new Pristine(form);

//     const successMessage = form.querySelector(".form-success__message");
//     form.addEventListener("submit", function (e) {
//       e.preventDefault();
//       // console.log(pristine);
//       const valid = pristine.validate();
//       if (valid) {
//         form.reset();
//         // successMessage.style.display = "block";
//       } else {
//         // successMessage.style.display = "none";
//       }
//     });
//   });

}