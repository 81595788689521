/* eslint-disable func-names */
// import "./lib/selectize";
// import ionRangeSlider from "ion-rangeslider";

export default function controls() {

    (function inputNumber() {
        const inputDivs = document.querySelectorAll('.input-number');
        if(!inputDivs) return;
  
        /* eslint-disable */
        for (let i = 0; i < inputDivs.length; i++) {
  
          const input = inputDivs[i].querySelector('input');
          const minus = inputDivs[i].querySelector('.minus');
          const plus = inputDivs[i].querySelector('.plus');
  
          function setValue(count) {
            count = count < 1 ? 1 : count;
            input.value = parseInt(count, 10) || 1;
          }
  
          input.addEventListener('change', () => {
            let val = input.value;
            setValue(val);
          });
  
          minus.addEventListener('click', function () {
            const count = parseInt(input.value, 10) - 1;
            setValue(count);
            input.dispatchEvent(new Event('change'));
            return false;
          });
  
          plus.addEventListener('click', function () {
            const count = parseInt(input.value, 10) + 1;
            setValue(count);
            input.dispatchEvent(new Event('change'));
            return false;
          });
        }
        /* eslint-enable */
  
      })();

    const header = document.querySelector('header');

    (function ProductImageChanger() {
        const block = document.querySelector('.product-data-images')
        if(!block) return;
        const images = block.querySelectorAll('.product-data-images__item')
        const imageLarge = block.querySelector('.product-data-images__item--large')
        if(!images) return;


        block.addEventListener('click', function(e) {
            e.preventDefault();
            if(e.target.classList.contains('product-data-images__item')) {
                const element = e.target
                if(element.classList.contains('active')) return;
                const url = element.dataset.source
                this.parentElement.querySelectorAll('.product-data-images__item').forEach(n => n.classList.toggle('active', n === e.target));
                imageLarge.src = url
            }
        })
        // images.forEach(image => {
        //     if(!image.classList.contains('product-data-images__item--large')) {
                
        //     }
        // })
    })();


    (function myTabs() {
        const block = document.querySelector('.product-tabs')
        if(!block) return;
        const controlsList = block.querySelectorAll('.product-tabs-controls-item')
        const contentList = block.querySelectorAll('.product-tabs-content-item')
        if(!controlsList || !contentList) return;


        block.addEventListener('click', function(e) {
            e.preventDefault();
            if(e.target.classList.contains('product-tabs-controls-item')) {
                const element = e.target
                if(element.classList.contains('active')) return;
                const contentBlock = block.querySelector(element.hash)
                // console.log(contentBlock);

                controlsList.forEach(n => n.classList.toggle('active', n === e.target));
                contentList.forEach(n => n.classList.toggle('active', n === contentBlock));
            }
        })
        // images.forEach(image => {
        //     if(!image.classList.contains('product-data-images__item--large')) {
                
        //     }
        // })
    })();

    (function addToCard() {
        const btnAddCart = document.querySelectorAll('.add-to-card');
        btnAddCart.forEach(btn => btn.addEventListener('click', (e) => {
            e.preventDefault();
            btn.classList.toggle('active')
        }));
    })();

    (function catalogCart() {
        const block = document.querySelector('.catalog-cart')
        const summ = document.querySelector('.catalog-cart-list-order__cost span')
        if(!block) return;
        const showCartBtn = block.querySelectorAll('.catalog-cart-list-selected__counter')
        if(!showCartBtn) return;
        let cartItemInput = block.querySelectorAll('.catalog-cart-contents-item__input')
        const cartItemRemove = block.querySelectorAll('.catalog-cart-contents-item__remove')


        const cartVisibleToggle = () => {
            const offsetHeader = parseInt(window.scrollY, 10) - header.clientHeight
            block.classList.toggle('cart-visible', offsetHeader >= 0);
        }
        cartVisibleToggle();

        window.addEventListener('scroll', cartVisibleToggle)



        function closeModal(e) {
            if(e.target.classList.contains('catalog-cart-list-selected__counter') || e.target.classList.contains('catalog-cart-contents') || e.target.closest('.catalog-cart-contents')) return;
            if(block.classList.contains('show-cart')) {
                block.classList.remove('show-cart');
            }
            document.body.removeEventListener('click', closeModal);
        }


        function declOfNum(number, titles) {  
            const cases = [2, 0, 1, 1, 1, 2];  
            return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
        }
    


        function updateSumm() {
            cartItemInput = block.querySelectorAll('.catalog-cart-contents-item__input')
            let total = 0;
            let count = 0;
            cartItemInput.forEach((item) => {
                const itemValue = parseFloat(item.value, 10) > 0 ? parseFloat(item.value, 10) : 0;
                // console.log(item);
                total += item.dataset.cost * itemValue
                count += itemValue
            });
            total = total.toLocaleString().replace(/,/g, ' ');;
            summ.textContent = `${total}`
            showCartBtn.forEach(item => {
                const element = item.querySelector('span')
                const text = declOfNum(count, ['контейнер', 'контейнера', 'контейнеров']);
                element.textContent = `${count} ${text}`
            })
            
            // declOfNum(count, ['найдена', 'найдено', 'найдены']);
            // const productValue = this.value;
            // let cost = productCost * productValue
            // cost = cost.toLocaleString().replace(/,/g, ' ');;
            // this.closest('.catalog-cart-contents-item').querySelector('.catalog-cart-contents-item__cost span').textContent = `${cost}`
        }
        function updateCost() {
            const productCost = this.dataset.cost
            const productValue = parseFloat(this.value, 10) > 0 ? parseFloat(this.value, 10) : 0;
            let cost = productCost * productValue
            cost = cost.toLocaleString().replace(/,/g, ' ');;
            this.closest('.catalog-cart-contents-item').querySelector('.catalog-cart-contents-item__cost span').textContent = `${cost}`
            updateSumm();
        }
        function checkNumber($event) {
            if($event.type === 'paste') $event.preventDefault();
            if(this.value.length >= 5) $event.preventDefault();
            const keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
          }

        function removeItem() {
            const $this = this
            setTimeout(function() {
                $this.closest('.catalog-cart-contents-item').remove()
                updateSumm();
            },0);
        }

        block.addEventListener('click', function(e) {
            if(e.target.classList.contains('catalog-cart-list-selected__counter')) {
                e.preventDefault();
                block.classList.add('show-cart');
                document.body.addEventListener('click', closeModal);
            }
            if(e.target.classList.contains('.catalog-cart-contents') || e.target.closest('.catalog-cart-contents')) {
                e.preventDefault();
            }
        });
        cartItemInput.forEach(item => item.addEventListener('paste', checkNumber))
        cartItemInput.forEach(item => item.addEventListener('keypress', checkNumber))
        cartItemInput.forEach(item => item.addEventListener('keyup', updateCost))
        cartItemRemove.forEach(item => item.addEventListener('click', removeItem))
    })();

    (function headerCart() {
        const cartBtn = header.querySelector('.header-submenu-cart-btn');
        // const cartModal = header.querySelector('.header-submenu-cart-window');

        const closeModal = function(event) {
            if(event.target.closest('.header-submenu-cart-window') || event.target.classList.contains('header-submenu-cart-window')) return;
            header.classList.remove('open-cart');
            document.body.removeEventListener('click', closeModal);
 
        }
        const openModal = function(event) {
            document.body.removeEventListener('click', closeModal);
            header.classList.add('open-cart');
            setTimeout(() => {
                document.body.addEventListener('click', closeModal);
            }, 100);
        }
        // function closeModal() {
        //     header.classList.add('open-cart')
        // }

        cartBtn.addEventListener('click', e => openModal(e));

    })();

    
    (function navToggle() {
        const btn = document.querySelector('.mobile-menu-toggle');
        // const menuList = document.querySelector('.mobile-menu-list');

        btn.addEventListener('click', function() {
            header.classList.toggle('menu-open')
        });

        // menuList.addEventListener('click', function(e) {
        //     if(e.target.classList.contains('mobile-menu-list-item')) {
        //         header.classList.toggle('menu-open')
        //     }
            
        // })


    })();


    // setTimeout(function() {
    //     $('.video-player').click(function() {
    //         const video = $(this)[0];
    //         const button = $(this).parent()
    //         if (video.paused) {
    //             video.play();
    //             button.addClass('playing')
    //         } else {
    //             video.pause();
    //             button.removeClass('playing')
    //         }
    //     });
    // }, 500)

}