import sliders from './sliders';
import modals from './modals';
import controls from './controls';
// import map from './map';
// import animation from './animation';

import checkBrowsers from "./lib/checkBrowsers";

global.ww = window.innerWidth;
global.wh = window.innerHeight;

window.$ = $;


$(document).ready(() => {
    document.body.className += ' ready';
    global.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    controls();
    sliders();
    modals();
    // map();
    // animation()


    const allResponsives = () => {
        global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        console.log('allResponsives!');
    };
    allResponsives();

    $(window).on('resize', function() {
        global.ww = window.innerWidth;
        global.wh = window.innerHeight;
        allResponsives();
    });
});