// eslint-disable-next-line import/no-unresolved
import { Swiper, Navigation, Pagination } from "swiper";

export default function sliders() {

  (function historySlider() {
    const swiper = new Swiper(".main-history-slider", {
      slidesPerView: 'auto',
      spaceBetween: 0,
      watchOverflow: true
    });
  })();

  (function simpleSlider() {
    // Install modules
    Swiper.use([Navigation, Pagination]);
    const swiper = new Swiper(".main-catalog-slider", {
      slidesPerView: 4,
      spaceBetween: 32,
      watchOverflow: true,
      navigation: {
        nextEl: ".main-catalog-slider-button-next",
        prevEl: ".main-catalog-slider-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 32,
        }
      }
    });

    const swiperGallery = new Swiper(".main-gallery-slider", {
      slidesPerView: 3,
      spaceBetween: 28,
      watchOverflow: true,
      navigation: {
        nextEl: ".main-gallery-slider-button-next",
        prevEl: ".main-gallery-slider-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 28,
        }
      }
    });
  })();


}